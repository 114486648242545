export const environment = {
  name: 'Production',
  version: '2.34.0.5707',
  production: true,
  sentry: {
    dsn: 'https://8a567cbe1759408a82f0600370df4e02@sentry.antony-it.de/24',
    environment: 'Production'
  },
  mock: false,
  authentication: {
    tokenName: 'auth-token'
  }
};
